import { fabric } from 'fabric'
import moment from 'moment'
import 'moment/locale/es'
import 'moment/locale/en-gb'

class FileControls {
    constructor(canvas, dateManager) {
        if (!(canvas instanceof fabric.Canvas)) {
            throw new Error('Canvas debe ser una instancia de fabric.Canvas')
        }
        this.canvas = canvas
        this.dateManager = dateManager // Instancia para gestionar fechas DateControls()
    }

    /**
     * Exporta el canvas como imagen (PNG o JPG).
     * @param {Object} options - Opciones de exportación.
     * @param {string} [options.imageFormat='png'] - Formato de la imagen ('png' o 'jpeg').
     * @param {number} [options.imageQuality=1] - Calidad de la imagen (solo para 'jpeg', de 0 a 1).
     * @param {number} [options.multiplier=3] - Factor de resolución (por defecto 3).
     */
    getCanvasImage = async ({ format = 'jpeg', quality = 0.7, multiplier = 3 } = {}) => {
        const logoGroup = await this._addLogoGom() // Añadir logo y fecha

        const dataUrl = this.canvas.toDataURL({
            format: format,
            quality: quality,
            multiplier: multiplier,
        })

        this._downloadFile(dataUrl, `canvas_image_${this.dateManager.getLastUpdateDateTimeFileFormat()}.${format}`)

        // Eliminar el logo después de la exportación
        this.canvas.remove(logoGroup)
        this.canvas.renderAll()
    }

    /**
     * Exporta el canvas en formato JSON.
     */
    getCanvasJSON() {
        const jsonString = JSON.stringify(this.toJSON())
        const blob = new Blob([jsonString], { type: 'application/json' })

        this._downloadFile(URL.createObjectURL(blob), `canvas_${this.dateManager.getLastUpdateDateTimeFileFormat()}.json`)
    }

    /**
     * Convierte el canvas a formato JSON, incluyendo propiedades personalizadas.
     * @returns {Object} - Objeto JSON del canvas.
     */
    toJSON() {
        const json = this.canvas.toJSON(['locationName', 'mapBounds', 'data', 'gomElement', 'lastUpdatedAt', 'createdAt'])
        json.objects = json.objects.filter(obj => obj.data?.grid !== true)
        return json
    }

    /**
     * Carga un canvas desde un objeto JSON.
     * @param {Object} jsonObject - Datos JSON del canvas.
     */
    setFromJSON = (jsonObject, callBack) => {
        this.canvas.loadFromJSON(jsonObject, () => {
            this.canvas.getObjects().forEach((object) => {
                if (object.data?.type === 'sectorGroup' || object.data?.locked) {
                    object.set({
                        lockMovementX: true,
                        lockMovementY: true,
                        lockRotation: true,
                        lockScalingX: true,
                        lockScalingY: true,
                        lockScalingFlip: true,
                        lockSkewingX: true,
                        lockSkewingY: true,
                        lockUniScaling: true,
                        evented: true,
                        selectable: true,
                        hasControls: false,
                        moveCursor: false,
                        hoverCursor: 'pointer',
                    })
                }
            })

            this.canvas.renderAll() // Refresca el canvas después de aplicar cambios
            callBack()
        })
    }

    /**
     * Descarga un archivo generado (imagen o JSON).
     * @param {string} fileUrl - URL del archivo.
     * @param {string} fileName - Nombre del archivo.
     * @private
     */
    _downloadFile(fileUrl, fileName) {
        const link = document.createElement('a')
        link.href = fileUrl
        link.download = fileName
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        URL.revokeObjectURL(fileUrl)
    }

    /**
     * Add GOM Fire logo
     * #ffc107
     */
    _addLogoGom = () => {
        return new Promise((resolve, reject) => {
            fabric.Image.fromURL('./logo_gom_fire_trans_sm.png', (img, error) => {
                if (error) {
                    reject(new Error('Error loading image'))
                    return
                }

                // Detectar el idioma del navegador
                const userLanguage = navigator.language || navigator.languages[0]
                moment.locale(userLanguage.startsWith('es') ? 'es' : 'en')
                const dateFormat = userLanguage.startsWith('es') ? 'DD/MM/YYYY HH:mm:ss' : 'MM/DD/YYYY HH:mm:ss'

                const padding = 10
                const textPadding = 15 // Espacio entre imagen y texto
                const exportDate = new Date().toLocaleDateString() // Obtener fecha actual
                const locationName = this.canvas.get('locationName') || 'GOM Fire SITAC'
                const lastUpdatedAt = this.canvas.get('lastUpdatedAt') || 'N/D'
                const textString = locationName + ' (' + moment(lastUpdatedAt).format(dateFormat) + ')'

                // Texto a la derecha del logo (alineado verticalmente)
                const logoText = new fabric.Text(textString, {
                    fontSize: 16,
                    fill: 'black',
                    fontFamily: 'Arial',
                    left: img.width + padding + textPadding, // A la derecha del logo
                    top: img.height / 2, // Centrado verticalmente con la imagen
                    originY: 'top'
                })

                // Determinar el tamaño del fondo
                const rectWidth = img.width + logoText.width + padding * 3 + textPadding
                const rectHeight = Math.max(img.height, logoText.height) + padding * 2

                // Fondo para el logo + texto
                const rect = new fabric.Rect({
                    left: 10,
                    top: 10,
                    width: rectWidth,
                    height: rectHeight,
                    fill: '#ffc10766',
                    rx: 5,
                    ry: 5,
                    selectable: false,
                    evented: false
                })

                // Ajustar posiciones dentro del fondo
                img.set({
                    left: rect.left + padding,
                    top: rect.top + rectHeight / 2 - img.height / 2,
                    selectable: false,
                    evented: false
                })

                logoText.set({
                    left: img.left + img.width + textPadding,
                    top: img.top + img.height / 2 - logoText.height / 2
                })

                // Agrupar imagen, texto y fondo
                const logoGroup = new fabric.Group([rect, img, logoText], {
                    selectable: false,
                    evented: false
                })

                this.canvas.add(logoGroup)
                this.canvas.renderAll()

                // Responder cuando todo esté listo
                resolve(logoGroup)
            })
        })
    }

}

export default FileControls
