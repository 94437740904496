import { useState, useEffect } from "react"

const useInternetAccess = () => {
    const [isOnline, setIsOnline] = useState(navigator.onLine)
    const [hasInternet, setHasInternet] = useState(null)

    useEffect(() => {
        const checkConnection = async () => {
            try {
                const response = await fetch("https://www.civilio.eu", { mode: "no-cors" })
                setHasInternet(true)
            } catch {
                setHasInternet(false)
            }
        }

        checkConnection()

        const handleOnline = () => {
            setIsOnline(true)
            checkConnection()
        }
        const handleOffline = () => {
            setIsOnline(false)
            setHasInternet(false)
        }

        window.addEventListener("online", handleOnline)
        window.addEventListener("offline", handleOffline)

        const interval = setInterval(checkConnection, 10000)

        return () => {
            window.removeEventListener("online", handleOnline)
            window.removeEventListener("offline", handleOffline)
            clearInterval(interval)
        }
    }, [])

    return { isOnline, hasInternet }
}

export default useInternetAccess
