import React, { useState } from 'react'
import { FormattedMessage, useIntl } from "react-intl"
import moment from 'moment'
import { Offcanvas, Form, Card, ListGroup, Stack, Badge, Row, Col } from 'react-bootstrap'
import { BsArrowClockwise, BsBookmarkFill, BsBroadcastPin, BsCardText, BsPersonBadge, BsRulers, BsStopwatch } from 'react-icons/bs'
import { getTagById } from '../../config/tag.config'
import { COLOR_CODES, getColorById, getContrastColor } from '../../config/color.config'

const SectorOffcanvas = ({ show, element, onHide, sectorObjects, parentNode, canvasRef }) => {
    const intl = useIntl()

    const [personInCharge, setPersonInCharge] = useState(element.data.personInCharge || '')
    const [resourceChannel, setResourceChannel] = useState(element.data.resourceChannel || '')
    const [description, setDescription] = useState(element.data.description || '')

    // Detectar el idioma del navegador
    const userLanguage = navigator.language || navigator.languages[0]
    moment.locale(userLanguage.startsWith('es') ? 'es' : 'en')
    const dateFormat = userLanguage.startsWith('es') ? 'DD/MM/YYYY HH:mm:ss' : 'MM/DD/YYYY HH:mm:ss'

    const handleInputChange = (setState) => (e) => {
        const value = e.target.value
        setState(value)
    }

    const handleInputBlur = (field, stateValue, originalValue) => () => {
        if (stateValue !== originalValue) {
            const dateTime = Date.now()
            canvasRef.setElementData(element, {
                [`${field}UpdatedAt`]: dateTime,
                [field]: stateValue,
            }, dateTime)
            if (field === 'resourceChannel') {
                handleEditResourceChannelUpdateElement(stateValue)
            }
        }
    }

    const handleEditPersonInCharge = handleInputChange(setPersonInCharge)
    const handleEditPersonInChargeBlur = handleInputBlur('personInCharge', personInCharge, element.data.personInCharge)
    const handleEditResourceChannel = handleInputChange(setResourceChannel)
    const handleEditResourceChannelBlur = handleInputBlur('resourceChannel', resourceChannel, element.data.resourceChannel)
    const handleEditResourceChannelUpdateElement = (value) => {
        sectorObjects.map(obj => {
            const dateTime = Date.now()
            canvasRef.setElementData(obj, {
                resourceChannelUpdatedAt: dateTime,
                resourceChannel: value,
            }, dateTime)
        })
    }
    const handleEditDescription = handleInputChange(setDescription)
    const handleEditDescriptionBlur = handleInputBlur('description', description, element.data.description)

    const getMeters2 = (areapx) => {
        const mapBounds = canvasRef.getMapBounds()
        if (mapBounds && mapBounds.pxPerMeter) {
            return parseFloat((areapx / (mapBounds.pxPerMeter * mapBounds.pxPerMeter)).toFixed(2)) + 'm²'
        } else {
            return parseFloat(areapx).toFixed(2) + 'px²'
        }
    }

    const SectorObjects = () => {
        const { elements, tags } = sectorObjects.reduce((acc, element) => {
            const tag = getTagById(element.data.as)
            if (!acc.elements[tag.id]) {
                acc.elements[tag.id] = []
                acc.tags[tag.id] = tag
            }

            acc.elements[tag.id].push(element.data)
            return acc
        }, { elements: {}, tags: {} })

        return Object.entries(elements).map(([tagId, items]) => (
            <Card key={tagId} className="shadow border-0 mb-2 p-0 m-0">
                <Card.Header className="bg-light">
                    <Stack direction="horizontal" gap={2} className="justify-content-between">
                        <span>
                            {!!tags[tagId] && <span>{tags[tagId].icon}</span>}&nbsp;
                            <strong>
                                <FormattedMessage
                                    id={'tag' + tagId.charAt(0).toUpperCase() + tagId.slice(1)}
                                    defaultMessage={tagId}
                                />
                            </strong>
                        </span>
                        <Badge bg="secondary">{items.length}</Badge>
                    </Stack>
                </Card.Header>
                <Card.Body className='p-0 m-1'>
                    <ListGroup variant="flush">
                        {items.map((item, index) => (
                            <Card
                                bg='light'
                                key={index}
                                className='p-0 mb-1'
                            >
                                <Card.Header className='p-1'
                                    style={{
                                        backgroundColor: getColorById(item.colorCodeId).color,
                                        borderStyle: item.isActive ? "solid" : "dashed",  // asegurando estilo sólido
                                        borderWidth: 3,
                                        borderColor: getContrastColor(getColorById(item.colorCodeId).color),
                                        borderRadius: 10,
                                        color: getContrastColor(getColorById(item.colorCodeId).color),
                                    }}
                                >
                                    {!!tags[tagId] && <span>{tags[tagId].icon}</span>} {item.title}
                                </Card.Header>
                                <Card.Body className='p-1'>
                                    <div className="d-flex justify-content-between w-100">
                                        <small><BsStopwatch /> {moment(item.createdAt).fromNow()}</small>
                                        <small><BsArrowClockwise />{moment(item.lastUpdatedAt).format(dateFormat)}</small>
                                    </div>
                                    <small>{item.description}</small>
                                </Card.Body>
                            </Card>
                        ))}
                    </ListGroup>
                </Card.Body>
            </Card>
        ))
    }

    return (
        <Offcanvas
            container={parentNode}
            show={show}
            onHide={onHide}
            placement={'start'}
        >
            <Offcanvas.Header
                closeButton
                style={{ backgroundColor: COLOR_CODES.COMMAND.color, color: getContrastColor(COLOR_CODES.COMMAND.color) }}
                closeVariant={getContrastColor(COLOR_CODES.COMMAND.color)}
            >
                <Offcanvas.Title>
                    <BsBookmarkFill size={20} /> {element.data.title ? element.data.title : element.data.id}
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>

                <Row className='p-0 mb-2'>
                    <Col className='m-0'>
                        <Form.Floating>
                            <Form.Control
                                id="sectorCreatedAt"
                                type="text"
                                size={'sm'}
                                value={moment(element.data.createdAt).format(dateFormat)}
                                disabled
                            />
                            <label htmlFor='sectorCreatedAt'>
                                <BsStopwatch className='me-1' />
                                <FormattedMessage id={'sectorDataCreatedAt'} defaultMessage={'Created on'} />
                            </label>
                        </Form.Floating>
                    </Col>
                    <Col className='m-0'>
                        <Form.Floating>
                            <Form.Control
                                id="sectorLastUpdatedAt"
                                type="text"
                                size={'sm'}
                                value={moment(element.data.lastUpdatedAt).format(dateFormat)}
                                disabled
                            />
                            <label htmlFor='sectorLastUpdatedAt'>
                                <BsArrowClockwise className='me-1' />
                                <FormattedMessage id={'sectorDataLastUpdatedAt'} defaultMessage={'Last modified'} />
                            </label>
                        </Form.Floating>
                    </Col>
                </Row>

                <Row className='p-0 mb-2'>
                    <Col className='m-0'>
                        <Form.Floating>
                            <Form.Control
                                id="sectorCommandChannel"
                                type="text"
                                size={'sm'}
                                value={element.data.commandChannel ? element.data.commandChannel : 'N/A'}
                                style={{ borderColor: COLOR_CODES.COMMAND.color }}
                                className='text-end'
                                disabled
                            />
                            <label htmlFor='sectorCommandChannel'>
                                <BsBroadcastPin className='me-1' />
                                <FormattedMessage id={'sectorDataCommandChannel'} defaultMessage={'Command channel'} />
                            </label>
                        </Form.Floating>
                    </Col>
                    <Col className='m-0'>
                        <Form.Floating>
                            <Form.Control
                                id="sectorResourceChannel"
                                type="text"
                                size={'sm'}
                                value={resourceChannel}
                                onChange={handleEditResourceChannel}
                                onBlur={handleEditResourceChannelBlur}
                                className='text-end'
                            />
                            <label htmlFor='sectorResourceChannel'>
                                <BsBroadcastPin className='me-1' />
                                <FormattedMessage id={'sectorDataResourceChannel'} defaultMessage={'Resources channel'} />
                            </label>
                        </Form.Floating>
                    </Col>
                </Row>

                <Form.Floating className="mb-2">
                    <Form.Control
                        id="sectorPersonInCharge"
                        type="text"
                        size={'sm'}
                        value={personInCharge || ''}
                        onChange={handleEditPersonInCharge}
                        onBlur={handleEditPersonInChargeBlur}
                    />
                    <label htmlFor='sectorPersonInCharge'>
                        <BsPersonBadge className='me-1' />
                        <FormattedMessage id={'sectorDataPersonInCharge'} defaultMessage={'Person in charge'} />
                    </label>
                </Form.Floating>

                <Form.Floating className="mb-2">
                    <Form.Control
                        id="sectorDescription"
                        as="textarea"
                        size={'sm'}
                        style={{ height: '100px' }}
                        value={description || ''}
                        onChange={handleEditDescription}
                        onBlur={handleEditDescriptionBlur}
                    />
                    <label htmlFor='sectorDescription'>
                        <BsCardText className='me-1' />
                        <FormattedMessage id={'sectorDataDescription'} defaultMessage={'Description'} />
                    </label>
                </Form.Floating>

                <Form.Floating>
                    <Form.Control
                        id="sectorAreaM2"
                        type="text"
                        size={'sm'}
                        value={getMeters2(element.data.areapx)}
                        disabled
                        className='text-end'
                    />
                    <label htmlFor='sectorAreaM2'>
                        <BsRulers className='me-1' />
                        <FormattedMessage id={'sectorDataAreaM2'} defaultMessage={'Area m²'} />
                    </label>
                </Form.Floating>

                <SectorObjects />

            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default SectorOffcanvas
